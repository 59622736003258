import React from "react";
import { Story, Meta } from "@storybook/react";

import Statistic, { Props } from "./Statistic";

const componentDescription = `
  Component to display numeric stats.\n
  Displays a numerical statistic value with a slash, where the total value is from the right side and the part value of the total is from the left side.\n
  ####Detailed description of \`data\` object fields:\n
  -\`value\` - number value to display as the statistic. Displays to the left of the slash;\n
  -\`total\` - number *total* value. Displays to the right of the slash;\n
  -\`title\` - additional text to display below the statistic numbers;\n
  -\`icon\` - additional the icon to display to the left of the statistics;\n
  -\`additionalClassNames\` - styles customization that will be applied to all statistic block;\n
  -\`titleClasses\` - styles customization for title;\n
  -\`valuesClasses\` - styles customization for stats numbers: \`value\` and \`total\`;\n
  -\`iconClasses\` - styles customization for icon;\n
`;

export default {
  title: "Components/Statistic",
  component: Statistic,
  parameters: {
    docs: {
      description: {
        component: componentDescription,
      },
    },
  },
} as Meta;

const Template: Story<Props> = (args) => <Statistic {...args} />;

export const Default = Template.bind({});
Default.args = {
  data: {
    value: 60,
    total: 100,
  }
};

export const WithTitle = Template.bind({});
WithTitle.args = {
  data: {
    value: 60,
    total: 100,
    title: "Biodiversity Impact Score",
  }
};

export const WithIcon = Template.bind({});
WithIcon.args = {
  data: {
    ...WithTitle.args.data,
    icon: "/images/restitution/noah/Biodiversity_Impact.svg",
  }
};

export const WithCustomization = Template.bind({});
WithCustomization.args = {
  data: {
    ...WithIcon.args.data,
    iconClasses: ["storybook-statistic-icon"],
    titleClasses: ["text-normal"],
    valuesClasses: ["storybook-color-3"],
    additionalClassNames: ["storybook-statistic-block"]
  }
};