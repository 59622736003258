import React from "react";
import { Story, Meta } from "@storybook/react";

import SectionText, { Props } from "./SectionText";

const componentDescription = `
  Simple component to display text. The text is in the html tag \`p\`.\n
  All customizations for the text could be implemented by using various CSS classes:
  \`additionalClassNames\`. These customizations will apply to all text.
  ####Detailed description of \`data\` object fields:\n
  -\`text\` - text content;\n
  -\`additionalClassNames\` - styles customization for the text content;\n
  -\`separate\` - field that indicates whether the text needs to be separated or not. Detailed information [here](#with-separator) ;\n
  -\`separator\` - delimiter value by which the text will be separated;\n
  -\`stringClassNames\` - style customization for each of the separated string;\n
  -\`customInlineStyles\` - inline styles object for the \`p\` html tag. Detailed information [here](#with-custom-inline-styles);\n
  -\`isOptional\` - field indicating that the \`SectionText\` can only be displayed under a certain condition, which is specified in this object;\n
  The \`isOptional\` field structure must be an *Object*:\n
    {
      test: \`condition_value\`
    }
  \n
`;

export default {
  title: "Components/SectionText",
  component: SectionText,
  parameters: {
    docs: {
      description: {
        component: componentDescription,
      },
    },
  },
} as Meta;

const Template: Story<Props> = (args) => <SectionText {...args} />;

export const Default = Template.bind({});
Default.args = {
  data: {
    text: "It's a simple component to display text",
  },
};

export const WithTextCustomization = Template.bind({});
WithTextCustomization.args = {
  data: {
    text: "It's a simple component to display text",
    additionalClassNames: ["text-uppercase", "text-bold"],
  },
};

export const WithDot = Template.bind({});
WithDot.args = {
  data: {
    text: "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat.",
    additionalClassNames: ["dot-item"]
  },
};

export const WithSeparator = Template.bind({});
const withSeparatorDescription = `
  The text (specified in the \`text\` variable) can be separated into multiple strings. \n
  Usage Criteria:
  - \`separate: true\`;
  - \`separator\` variable need to be defined as a string with separator value;
`;
WithSeparator.parameters = {
  docs: {
    description: {
      story: withSeparatorDescription,
    },
  },
};
WithSeparator.args = {
  data: {
    text: "Prélèvement des gonades;Création de lots selon la taille, couleur et fermeté des œufs;Tamisage, rinçage, pesée;Salage avec du sel minier d’origine France;Mise en boîte",
    separate: true,
    separator: ";",
  },
};

export const WithSeparatorAndCustomization = Template.bind({});
const withSeparatorClassDescription = `
  Css class can be defined for each of the separated string. \`stringClassNames\` is used for this purpose.\n
  In this example the \`"dot-item"\` class display a dot at the beginning of the each separated string.
`;
WithSeparatorAndCustomization.parameters = {
  docs: {
    description: {
      story: withSeparatorClassDescription,
    },
  },
};
WithSeparatorAndCustomization.args = {
  data: {
    ...WithSeparator.args.data,
    stringClassNames: ["dot-item"],
  },
};

export const WithCustomInlineStyles = Template.bind({});
const customInlineDescription = `
  \`customInlineStyles\` is an Object where object key is *CSS property name* and object value is *CSS property value*.\n
  Since the inline CSS is written in a JavaScript object, properties with two names, like \`text-decoration\`, must be written with camel case syntax: \`textDecoration\`.\n
  \`customInlineStyles\` recommended to use when you need to apply dynamic style values. For example: dynamic color for text. It is not recommended to use for the static styles.\n
  For static styles values it is recommended to use \`additionalClassNames\`.
`;
WithCustomInlineStyles.parameters = {
  docs: {
    description: {
      story: customInlineDescription,
    },
  },
};
WithCustomInlineStyles.args = {
  data: {
    text: "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.",
    customInlineStyles: {
      "color": "#2691c4",
      "textDecoration": "underline"
    }
  }
};