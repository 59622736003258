import React from "react";
import { Story, Meta } from "@storybook/react";

import Card, { Props } from "./Card";

const componentDescription = `
  UI component with a structure:\n
  - on the left side of the component is **Rounded Card Image**.\n
  - on the right side of the component there is any **Card Content**: text of any kind, links, etc.\n
  \n\n
  Usually this component is used on the Homepage of the Restitution.\n
  #####Detailed description of \`data\` object fields:\n
  -\`image\` - link to the default *Rounded Card Image*. This image is inserted as a background image for the \`div\` block;\n
  -\`imageClasses\` - styles customization that will be applied to the \`image\`;\n
  -\`content\` - *required* - the *Card Content* that will be located on the right side of the component.\n
  -\`contentClasses\` - styles customization that will be applied to the \`content\`;\n
  -\`additionalClassNames\` - styles customization that will be applied to all *Card* component;\n
  -\`customize\` - field that indicates whether the *Card Image* needs to be customized or not. More about [Card Image customization](#image-customization);\n
  -\`customSuffix\` - field is used to customize the *Card Image*;\n
  -\`product\` - field is used to customize the *Card Image*;\n
  -\`business\` - field containing the name of the business. Used for *Card Image* customization;\n
  -\`isOptional\` - field indicating that the \`Card\` can only be displayed under a certain condition, which is specified in this object;\n
  The \`isOptional\` field structure must be an *Object*:\n
    {
      test: \`condition_value\`
    }
  \nThe \`content\` field structure must be an *Array of Objects*:\n
    [
      {
        name: string;
        data: object;
      },
    ]
`;

export default {
  title: "Containers/Card",
  component: Card,
  parameters: {
    docs: {
      description: {
        component: componentDescription,
      },
    },
    backgrounds: {
      default: 'light beige',
    },
  },
} as Meta;

const Template: Story<Props> = (args) => <Card {...args} />;

export const BasicWithBigImage = Template.bind({});
BasicWithBigImage.args = {
  data: {
    content: [
      {
        "name": "SectionText",
        "data": {
          "text": "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.",
        }
      }
    ],
    image: "/images/restitution/meo/ACCUEIL_DEGUSTATION_OK.jpg",
    imageClasses: ["spa-card-image-big"]
  }
};

export const BasicWithSmallImage = Template.bind({});
BasicWithSmallImage.args = {
  data: {
    ...BasicWithBigImage.args.data,
    imageClasses: ["spa-card-image-small"]
  }
};

export const Example1 = Template.bind({});
Example1.args = {
  data: {
    image: "/images/restitution/meo/ACCUEIL_DEGUSTATION_OK.jpg",
    imageClasses: ["spa-card-image-big"],
    contentClasses: ["space-between", "align-items-center"],
    content: [
      {
        "name": "SimpleContainer",
        "data": {
          "children": [
            {
              "name": "SectionText",
              "data": {
                "text": "Origine et récolte du café",
                "additionalClassNames": [
                  "card-navigation-header"
                ]
              }
            },
            {
              "name": "SectionText",
              "data": {
                "additionalClassNames": [
                  "section-text"
                ],
                "text": "Colombie, Honduras, Pérou"
              }
            },
            {
              "name": "SectionText",
              "data": {
                "additionalClassNames": [
                  "card-navigation-subheader"
                ],
                "text": "Subheader"
              }
            },
          ]
        }
      },
      {
        "name": "Image",
        "data": {
          "src": "/images/restitution/meo/right_arrow.svg",
          "additionalClassNames": ["spa-navigation-right-arrow"]
        }
      }
    ]
  }
};

export const Example1WithLines = Template.bind({});
Example1WithLines.args = {
  data: {
    ...Example1.args.data,
    content: [
      {
        "name": "SimpleContainer",
        "data": {
          "additionalClassNames": ["spa-navigation-lines"]
        }
      },
      ...Example1.args.data.content,
    ]
  }
};

export const Example1WithDotes = Template.bind({});
Example1WithDotes.args = {
  data: {
    ...Example1.args.data,
    content: [
      {
        "name": "SimpleContainer",
        "data": {
          "additionalClassNames": ["spa-navigation-dotes"]
        }
      },
      ...Example1.args.data.content,
    ]
  }
};

export const ImageCustomization = Template.bind({});
const customImageDescription = `
  Each business can have several Products. Each Product can have its own *Card Image*. This images for Products are stored on *s3*.
  If the Product doesn't have its own image on *s3*, then the background image will be the default image from field \`image\` of the \`data\` object.\n
  **Activate** image customization: \`customize: true\`\n
  The **url** of the Product Card Image on s3 is formed like this:\n
      const cardUrl = <REACT_APP_BUCKET><business>/v1/non-traced/<productName>_<customSuffix>.jpg;\n
  >where: *REACT_APP_BUCKET* - env variable that contains a link to the s3 bucket;\n
  >*business* - business name that is defined in the \`business\` field of \`data\` object;\n
  >*productName* - \`product\` field value. Value converted to lower case and spaces replaced with '_';\n
  >*customSuffix* - suffix for a specific image that is defined in the \`customSuffix\` field of \`data\` object. 
`;
ImageCustomization.parameters = {
  docs: {
    description: {
      story: customImageDescription,
    },
  },
};
ImageCustomization.args = {
  data: {
    ...Example1.args.data,
    customize: true,
    business: "soufflet",
    customSuffix: "harvesting",
    product: "brasserie_de_chandres",
  }
};