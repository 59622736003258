import React from "react";
import { Story, Meta } from "@storybook/react";

import Form, { Props } from "./Form";

const componentDescription = `
  UI component that is displayed on the Form page of Restitution.
  The form contains 1 input field for entering the lot number.\n
  The form submit button is disabled when the input field is empty and \`inputRequired: true\`.\n
  #####Detailed description of \`data\` object fields:\n
  -\`callback\` - api endpoint for form submission;\n
  -\`inputDescription\` - text that is displayed under the input field;\n
  -\`question\` - question component;\n
  The \`question\` field structure must be an *Object*:\n
    {
      name: [string];
      data: [object];
    },
  \n-\`inputRequired\` - specifies whether the input should be a required field to submit form. *Default value*: \`true\`;\n
  -\`inputLabel\` - caption for the input field that displays at the top of the input;\n
  -\`commonErrorMessage\` - error message when form submitting failed;\n
  -\`submitButtonText\` - label for submit button;\n
  -\`ageChecker\` - specifies whether to display the Age checker;\n
  -\`ageErrorMessage\` - error message when user answered negative in the Age checker;\n
  -\`ageQuestion\` - text of the question for the Age checker;\n
  -\`ageNoBtn\` - label for the negative button in the Age checker;\n
  -\`ageYesBtn\` - label for the positive button in the Age checker;\n
  -\`defaultValues\` - default value for the input field.
   The \`defaultValues\` field structure must be an *Object*:\n
    {
      lot: [string]
    }
  \n-\`loaderInBtn\` - specifies whether to display the loader inside the submit button when submitting. Default value: *false*;\n
  - \`lotimage\` - component that is displayed under \`inputDescription\`. 
  Usually this component is used to display lot image to better understand where exactly the user can find the lot number. (e.g.: Carlsberg restitution);\n
  - \`inputPlaceholder\` - placeholder text for the input;\n
`;

export default {
  title: "Containers/Form",
  component: Form,
  parameters: {
    docs: {
      description: {
        component: componentDescription,
      },
    },
  },
} as Meta;

const Template: Story<Props> = (args) => <Form {...args} />

export const Basic = Template.bind({});
Basic.args = {
  data: {
    callback: "/api/schemas/business_name",
    submitButtonText: "Submit",
    commonErrorMessage: "This lot number is not yet known",
  }
};

export const WithInputDescription = Template.bind({});
WithInputDescription.args = {
  data: {
    ...Basic.args.data,
    inputDescription: "The lot number is located on the bottom of your box",
  }
};

export const WithQuestionAndInputLabel = Template.bind({});
WithQuestionAndInputLabel.args = {
  data: {
    ...WithInputDescription.args.data,
    inputLabel: "Lot number",
    question: {
      "name": "ModalContainer",
      "data": {
        "trigger": {
          "name": "Image",
          "data": {
            "src": "/images/restitution/soufflet/information.png",
            "additionalClassNames": ["form-question"]
          }
        },
        "cbId": "closeModal",
        "children": [
          {
            "name": "SimpleContainer",
            "data": {
              "additionalClassNames": ["spa-padding-1"],
              "children": [
                {
                  "name": "SectionText",
                  "data": {
                    "text": "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.",
                  }
                },
                {
                  "name": "Button",
                  "data": {
                    "text": "FERMER",
                    "cbId": "closeModal"
                  }
                }
              ]
            }
          }
        ]
      }
    }
  }
};

export const WithAgeChecker = Template.bind({});
WithAgeChecker.args = {
  data: {
    ...WithInputDescription.args.data,
    inputLabel: "Lot number",
    ageChecker: true,
    ageQuestion: "Are you an adult?",
    ageYesBtn: "YES",
    ageNoBtn: "NO",
    ageErrorMessage: "Sorry, you cannot access the content",
  }
};

export const WithDefaultValue = Template.bind({});
WithDefaultValue.args = {
  data: {
    ...WithInputDescription.args.data,
    defaultValues: {
      lot: "lot_number_1"
    }
  }
};