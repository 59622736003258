import React from "react";
import { Story, Meta } from "@storybook/react";

import Image, { Props } from "./Image";

const componentDescription = `
  UI component to display image.\n
  The image can be rendered as:\n
  - image in html tag \`img\`; \n
  - html block \`div\` with the background image.\n\n
  ####Detailed **description** of \`data\` object fields:\n
  -\`src\` - link to the image. Define this field if want to see image in the \`img\` tag.\n
  -\`backgroundImage\` - link to the image. Define this field if want to see image like background image of the block.\n
  -\`additionalClassNames\` -  styles customization that will be applied to the Image.\n
  -\`placeholderImage\` - link to the image that will be displayed if the \`src\` is not specified.\n\n
  If \`src\` and \`placeholderImage\` are not defined or empty strings, **Image** component will not rendered on the page.
`;

export default {
  title: "Components/Image",
  component: Image,
  parameters: {
    docs: {
      description: {
        component: componentDescription,
      },
    },
  },
} as Meta;

const Template: Story<Props> = (args) => <Image {...args} />;
export const ImageExample = Template.bind({});
ImageExample.args = {
  data: {
    src: "/images/restitution/noah/project_image.png",
    additionalClassNames: [""],
  },
};

export const BackgroundExample = Template.bind({});
BackgroundExample.args = {
  data: {
    backgroundImage: "/images/restitution/kaviar/Photo_BackgroundMarques_VF.jpg",
    additionalClassNames: ["w-100", "h-170px", "default-background", "center-background-position"],
  },
};

export const Logo = Template.bind({});
const logoDescription = `
  To apply the Logo on the page, need to specify the path to the desired image in the field \`backgroundImage\` 
  and specify the class \`spa-logo\` in the field \`additionalClassNames\`.
`;
Logo.parameters = {
  docs: {
    description: {
      story: logoDescription,
    }
  }
}
Logo.args = {
  data: {
    backgroundImage: "https://s3-eu-west-1.amazonaws.com/uat.assets.crystalchain.io/meo/v1/traced/files/460/3D-KOOTA_HARMONIEUX_MOULU_250g.png",
    additionalClassNames: ["spa-logo"],
  },
};

export const ImageBannerOnThePages = Template.bind({});
const imageBannerDescription = `
  This banner is usually used as banners on restitution pages.
  (For example: It is on the pages 'Maltage', 'Houblon' in the Soufflet restitution and on the pages 'Approvisionnement' and etc in the Meo restitution).\n
  Don't confuse with the Top Banner that is used on the root page. More about Top Banner - [here](?id=containers-simplecontainer--top-banner-customization).\n
  To apply the Image Banner on the page, need to specify the path to the desired image in the field \`backgroundImage\` 
  and specify the classes: \`w-100\`, \`h-20\`, \`default-background\` - in the field \`additionalClassNames\`.
`;
ImageBannerOnThePages.parameters = {
  docs: {
    description: {
      story: imageBannerDescription,
    }
  }
};
ImageBannerOnThePages.args = {
  data: {
    backgroundImage: "/images/restitution/soufflet/Orge.jpg",
    additionalClassNames: [
      "w-100",
      "h-20",
      "default-background",
      "center-background-position"
    ]
  }
};

export const PlaceholderExample = Template.bind({});
PlaceholderExample.args = {
  data: {
    src: "",
    placeholderImage: "https://via.placeholder.com/300",
    additionalClassNames: [""],
  }
};