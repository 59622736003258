import React from "react";
import { Story, Meta } from "@storybook/react";

import Legend, { Props } from "./Legend";

const componentDescription = `
  UI component used to display the Map legend.\n
  **Legend Label** is each *key* of \`data\` object converted to uppercase.\n
  **Legend Icon** is \`data[key].icon\` value.\n
  The \`data\` object structure:\n
    {
      label: {
        icon: 'icon_url'
      },
    }\n
  >  **Note**: This component is used very rarely, because has little customization. In most Restitutions recently [ListItem](?id=containers-listitem--example-1) component is more often used for the Map legend.
`;

export default {
  title: "Components/Legend",
  component: Legend,
  parameters: {
    docs: {
      description: {
        component: componentDescription,
      },
    },
  },
} as Meta;

const Template: Story<Props> = (args) => <Legend {...args} />;

export const Basic = Template.bind({});
Basic.args = {
  data: {
    silos: {
      icon: "/images/restitution/soufflet/icons/Silos_Icon.png"
    },
    malteries: {
      icon: "/images/restitution/soufflet/icons/Maltery_Icon.png"
    },
    brasseries: {
      icon: "/images/restitution/soufflet/icons/Brewery_icon.png"
    }
  },
};