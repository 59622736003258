import React from "react";
import { Story, Meta } from "@storybook/react";

import Button, { Props } from "./Button";

const componentDescription = `
  Button UI component for user interaction.\n
  All customizations for this component could be implemented by using various CSS classes:
  \`additionalClassNames\`, \`iconClasses\`, \`textClasses\`.\n
  Default button looks like: 
`;

export default {
  title: "Components/Button",
  component: Button,
  parameters: {
    docs: {
      description: {
        component: componentDescription,
      },
    },
    backgrounds: {
      "default": "light beige"
    }
  },
} as Meta;

const Template: Story<Props> = (args) => <Button {...args} />;

export const Default = Template.bind({});
Default.args = {
  data: {
    text: "Button",
    icon: "",
    additionalClassNames: [],
    iconClasses: [],
    textClasses: [],
  },
};

export const Style1 = Template.bind({});
Style1.args = {
  data: {
    text: "Button",
    icon: "",
    additionalClassNames: ["spa-button-style1"],
    textClasses: [],
  },
};

export const Style2 = Template.bind({});
Style2.args = {
  data: {
    text: "Button",
    icon: "",
    additionalClassNames: ["spa-button-style2"],
    textClasses: [],
  },
};

export const Style1WithCustomText = Template.bind({});
Style1WithCustomText.args = {
  data: {
    ...Style1.args.data,
    textClasses: ["text-uppercase"],
  },
};

export const Style1InCustomColor = Template.bind({});
Style1InCustomColor.args = {
  data: {
    ...Style1.args.data,
    additionalClassNames: ["spa-button-style1", "storybook-border-color-1", "storybook-color-2"],
  },
};

export const Style1WithIcon = Template.bind({});
Style1WithIcon.args = {
  data: {
    ...Style1.args.data,
    icon: "/images/restitution/crc/video_icon.svg",
    iconClasses: [],
  },
};



