import React from "react";
import { Story, Meta } from "@storybook/react";

import LinkContainer, { Props } from "./LinkContainer";
import * as LocationCard from '../../components/LocationCard/LocationCard.stories';

const componentDescription = `
  Navigation UI component: navigation occurs **inside** the Restitution. \n
  Redirection via external links is not possible for this component, for this it is better to use the *Link* component.\n
  Detailed description of \`data\` object fields:\n
  -\`path\` - the path where the redirect should be;\n
  -\`children\` - component that is a trigger for redirection;\n
  -\`isAbsolute\` - specifies the type of path to redirect;\n
  -\`isOptional\` - field indicating that the \`LinkContainer\` can only be displayed under a certain condition, which is specified in this object. 
  The \`isOptional\` field structure must be an *Object*:\n
    {
      test: \`condition_value\`
    }
  \nThe *root* of the restitution URL: \`https://t.crystalchain.io/[business]/[product_name]\` or \`https://t.crystalchain.io/[business]/[product_name]/[EAN]\`.\n
  If \`isAbsolute: true\` then the redirection will occur from the *root URL* regardless of which page it was triggered from. 
  Full redirection URL will be: \`https://t.crystalchain.io/[business]/[product_name]/[EAN]/[path_from_the_component]\`\n
  If \`isAbsolute: false\` then the redirection will occur from the URL of the current page it was triggered from (this may not always be *root*).
  In this case full redirection URL will be: \`https://t.crystalchain.io/[business]/[product_name]/[EAN]/[path_of_current_page]/[path_from_the_component]\`\n
`;

export default {
  title: "Containers/LinkContainer",
  component: LinkContainer,
  parameters: {
    docs: {
      description: {
        component: componentDescription,
      },
    },
  },
} as Meta;

const Template: Story<Props> = (args) => <LinkContainer {...args} />;


export const Example = Template.bind({});
Example.args = {
  data: {
    path: '',
    children: [
      {
        name: "LocationCard",
        data: LocationCard.Basic.args.data,
      }
    ]
  }
}