import React from "react";
import { Story, Meta } from "@storybook/react";

import ModalContainer, { Props } from "./ModalContainer";
import * as ButtonStories from "../../components/Button/Button.stories";
import * as SectionTextStories from "../../components/SectionText/SectionText.stories";

const componentDescription = `
  UI component that displays the Modal window.\n
  #####Detailed description of \`data\` object fields:\n
  -\`trigger\` - *required* - component that is a trigger for opening the modal window;\n
  -\`children\` -  modal content - array of components that will be displayed inside the modal window;\n
  -\`cbId\` - *required* - action for the close button of the current modal window. Possible value: "closeModal";\n
  -\`isOptional\` - field indicating that the modal window's trigger can only be displayed under a certain condition, which is specified in this object;\n
  The \`isOptional\` field structure must be an *Object*:\n
    {
      test: \`string_with_condition\`
    }\n
`;

export default {
  title: "Containers/ModalContainer",
  component: ModalContainer,
  parameters: {
    docs: {
      description: {
        component: componentDescription,
      },
    },
  },
} as Meta;

const Template: Story<Props> = (args) => <ModalContainer {...args} />;

export const Basic = Template.bind({});
Basic.args = {
  data: {
    trigger: {
      name: "Button",
      data: {
        ...ButtonStories.Style1.args.data,
        text: "Open modal",
      },
    },
    cbId: "closeModal",
    children: [
      {
        name: "SimpleContainer",
        data: {
          additionalClassNames: ["spa-padding-1"],
          children: [
            {
              name: "SectionText",
              ...SectionTextStories.WithTextCustomization.args,
            },
            {
              "name": "SimpleContainer",
              "data": {
                "additionalClassNames": [
                  "d-flex",
                  "justify-content-end",
                ],
                "children": [
                  {
                    "name": "Button",
                    "data": {
                      "text": "Close",
                      "cbId": "closeModal",
                      "additionalClassNames": [""]
                    }
                  }
                ]
              }
            }
          ],
        },
      },
    ],
  },
};