import React from "react";
import { Story, Meta } from "@storybook/react";

import TraceabilityHeader, { Props } from "./TraceabilityHeader";

const componentDescription = `
  UI component that adds \`header\` html tag to the page. The content of this tag can be text - value from the \`title\` field.\n
  All customizations for the text could be implemented by using various CSS classes:
  \`additionalClassNames\`.\n
  ClassName for default styles is \`traceability-header\`.
`;

export default {
  title: "Components/TraceabilityHeader",
  component: TraceabilityHeader,
  parameters: {
    docs: {
      description: {
        component: componentDescription,
      },
    },
  },
} as Meta;

const Template: Story<Props> = (args) => <TraceabilityHeader {...args} />;

export const WithDefaultStyles = Template.bind({});
WithDefaultStyles.args = {
  data: {
    title: 'La traçabilité au service de la transparence - Par Crystalchain',
    additionalClassNames: ['traceability-header', 'text-bold'],
  }
};