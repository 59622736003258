import React from "react";
import { Story, Meta } from "@storybook/react";

import Loader from "./Loader";

const componentDescription = `
  Loader component.\n
  This component has no dependency on props.
`;

export default {
  title: "Components/Loader",
  component: Loader,
  parameters: {
    docs: {
      description: {
        component: componentDescription,
      },
    },
  },
  decorators: [
    (Story) => (
      <div className={"loader-screen"}>
        <Story />
      </div>
    ),
  ],
} as Meta;

const Template: Story<any> = () => <Loader />;

export const Basic = Template.bind({});




