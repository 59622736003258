import React from "react";
import { Story, Meta } from "@storybook/react";

import SectionSeparatedText, { Props } from "./SectionSeparatedText";

const componentDescription = `
  UI Component for displaying text with the ability to apply different styles for different parts of the text.\n
  All text is in the html tag \`p\`. Separate parts of the text are in the \`span\` html tag.\n
  ####Detailed description of \`data\` object fields:\n
  -\`label\` - first part of the text;\n
  -\`text\` - second part of the text;\n
  -\`customInlineStyles\` - inline styles object for the \`p\` html tag. Detailed information [here](#with-custom-inline-styles);\n
  -\`additionalClassNames\` - styles customization that will be applied to all text;\n
  -\`textClasses\` - styles customization that will be applied to the second part of the text (for the value in \`text\` field);\n
  -\`isOptional\`, \`isOptionalLabel\` - fields indicating that the \`SectionSeparatedText\` can only be displayed under a certain condition, which is specified in this object;\n
  The \`isOptional\`, \`isOptionalLabel\` fields structure must be an *Object*:\n
    {
      test: \`condition_value\`
    }\n
`;

export default {
  title: "Components/SectionSeparatedText",
  component: SectionSeparatedText,
  parameters: {
    docs: {
      description: {
        component: componentDescription,
      },
    },
  },
} as Meta;

const Template: Story<Props> = (args) => <SectionSeparatedText {...args} />;

export const Default = Template.bind({});
Default.args = {
  data: {
    label: "Label: ",
    text: "Text",
  }
};

export const WithCustomization = Template.bind({});
WithCustomization.args = {
  data: {
    label: "Date of processing: ",
    text: "22 Feb 2022",
    additionalClassNames: ["text-bold"],
    textClasses: ["storybook-color-2"]
  }
};

export const WithCustomInlineStyles = Template.bind({});
const customInlineDescription = `
  \`customInlineStyles\` is an Object where object key is *CSS property name* and object value is *CSS property value*.\n
  Since the inline CSS is written in a JavaScript object, properties with two names, like \`font-weight\`, must be written with camel case syntax: \`fontWeight\`.\n
  \`customInlineStyles\` recommended to use when you need to apply dynamic style values. For example: dynamic color for text. It is not recommended to use for the static styles.\n
  For static styles values it is recommended to use \`additionalClassNames\` and \`textClasses\`.
`;
WithCustomInlineStyles.parameters = {
  docs: {
    description: {
      story: customInlineDescription,
    },
  },
};
WithCustomInlineStyles.args = {
  data: {
    label: "Date of processing: ",
    text: "22 Feb 2022",
    customInlineStyles: {
      "color": "#2691c4",
      "fontWeight": "bold"
    }
  }
};
