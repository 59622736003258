import React from "react";
import { Story, Meta } from "@storybook/react";

import LanguageSelector, { Props } from "./LanguageSelector";

const componentDescription = `
  UI component that allows the user to change the language of the Restitution.\n
  #####Detailed description of \`data\` object fields:\n
  -\`langList\` - *required* - selector options - list of all languages that Restitution has;\n
  -\`lang\` - *required* - selected language - the language in which the page is currently displayed;\n
  -\`displayFlagInSelectedValue\` - specifies whether to display flag icon for the selected value;\n
  -\`displayTextInSelectedValue\` - specifies whether to display text for the selected value; Default value - *true*\n
  -\`displayFlagInOption\` - specifies whether to display flag icons for each option;\n
  -\`displayTextInOption\` - specifies whether to display text for each option; Default value - *true*.\n
  -\`selectedOptionColor\` - specifies what color the selected value should have in the list of options;\n
  -\`additionalClassNames\` - styles customization that will be applied to *LanguageSelector* component;\n
  #####Languages:
  All languages are defined in a property \`langList\` as an array of objects, where each object corresponds to a language.\n
  *language object* - an object containing all the necessary information about 1 language: \n
  \`code\` - value which is used for API calls. 
  Each language code is the [ISO-639-1 Language Codes](https://en.wikipedia.org/wiki/List_of_ISO_639-1_codes)\n
  \`label\` - value which is used as label value for selector option.\n
  \`country_code\` - value which is used for displaying flag icon in the language selector. 
  Each \`country_code\` is the [ISO-3166 ALPHA-2 Country Codes](https://www.iban.com/country-codes)\n

  ##### Logic:
  When the user selects a new language value, *Restitution UI* do call to the *Restitution backend*.\n
  Endpoint: \n
    /api/schemas/[business]/[ean]/[lot_number]?lang=[selectedOption.code] \n
  or \n
    /api/schemas/[business]/[ean]?lang=[selectedOption.code] \n
  
  Then, the Restitution UI receives the response object with a new page schema and renders restitution page according to this schema. \n
  #####Libraries:
  This component is based on \`Select\` component of the [react-select](https://react-select.com/home) library. \n
  The [country-flag-icons](https://www.npmjs.com/package/country-flag-icons) library is used for flag icons.
`;

export default {
  title: "Components/LanguageSelector",
  component: LanguageSelector,
  parameters: {
    docs: {
      description: {
        component: componentDescription,
      },
    },
    backgrounds: {
      default: "light beige",
    }
  },
} as Meta;

const Template: Story<Props> = (args) => <LanguageSelector {...args} />;
const langList = [
  {
    "code": "en",
    "label": "English",
    "country_code": "GB"
  },
  {
    "code": "fr",
    "label": "Français",
    "country_code": "FR"
  },
  {
    "code": "it",
    "label": "Italiano",
    "country_code": "IT"
  }
];

export const Default = Template.bind({});
Default.args = {
  data: {
    langList,
    lang: "en"
  }
};

export const WithFlagsInSelectedValueOnly = Template.bind({});
WithFlagsInSelectedValueOnly.args = {
  data: {
    langList,
    lang: "fr",
    displayFlagInSelectedValue: true,
  }
};

export const WithFlagsInOptionsOnly = Template.bind({});
WithFlagsInOptionsOnly.args = {
  data: {
    langList,
    lang: "it",
    displayFlagInOption: true,
  }
};

export const WithTextAndFlagsInSelectedValueAndOptions = Template.bind({});
WithTextAndFlagsInSelectedValueAndOptions.args = {
  data: {
    langList,
    lang: "en",
    displayFlagInSelectedValue: true,
    displayFlagInOption: true,
  }
};

export const WithOnlyFlags = Template.bind({});
WithOnlyFlags.args = {
  data: {
    langList,
    lang: "fr",
    displayFlagInOption: true,
    displayTextInOption: false,
    displayFlagInSelectedValue: true,
    displayTextInSelectedValue: false,
  }
};

export const WithTextOnlyInSelectedValue = Template.bind({});
WithTextOnlyInSelectedValue.args = {
  data: {
    langList,
    lang: "it",
    displayFlagInSelectedValue: true,
    displayTextInOption: false,
    displayFlagInOption: true,
  }
};

export const WithTextOnlyInOptions = Template.bind({});
WithTextOnlyInOptions.args = {
  data: {
    langList,
    lang: "en",
    displayFlagInSelectedValue: true,
    displayTextInSelectedValue: false,
    displayFlagInOption: true,
  }
};

export const WithCustomSelectedOptionColor = Template.bind({});
WithCustomSelectedOptionColor.args = {
  data: {
    ...WithTextAndFlagsInSelectedValueAndOptions.args.data,
    selectedOptionColor: "#6c6c6c"
  }
};