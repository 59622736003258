import React from "react";
import { Story, Meta } from "@storybook/react";

import YouTube, { Props } from "./YouTube";

const componentDescription = `
  UI component for displaying the video specified in the \`src\` field.\n
  Video is rendered in the \`iframe\` html tag.
  Iframe size is 100% of the screen or block width of the parent.
`;

export default {
  title: "Components/YouTube",
  component: YouTube,
  parameters: {
    docs: {
      description: {
        component: componentDescription,
      },
    },
  },
} as Meta;

const Template: Story<Props> = (args) => <YouTube {...args} />;

export const Basic = Template.bind({});
Basic.args = {
  data: {
    src: 'https://www.youtube.com/embed/BHACKCNDMW8',
  }
};