import React from "react";
import { Story, Meta } from "@storybook/react";

import SimpleContainer, { Props } from "./SimpleContainer";
import * as ProgressBarStories from "../ProgressBar/ProgressBar.stories";
import * as SectionTextStories from "../../components/SectionText/SectionText.stories";

const componentDescription = `
  Simple UI component that serves as a **Wrapper**.\n
  The main goal of this component is to combine all the components that are specified in the \`children\` field into a parent html \`div\` block.
  Child components can be absolutely anything: text components, image components, modal window, etc.\n
  
  ####Banner customization
  This component has the ability to customize the background banner depending on the Product.
  [Learn More](#banner-customization)
  
  #####Detailed description of \`data\` object fields:\n
  -\`additionalClassNames\` - styles customization that will be applied to all *SimpleContainer* component;\n
  -\`customize\` - field that indicates whether the *Background Banner* needs to be customized or not. More about [Banner customization](#banner-customization);\n
  -\`product\` - field is used to customize the *Background Banner*;\n
  -\`src\` - link to the default *Background Banner*;\n
  -\`business\` - field containing the name of the business. Used for *Background Banner* customization;\n
  -\`children\` - child components to be wrapped by *SimpleContainer* component;\n
  The \`children\` field structure must be an *Array of Objects*:\n
    [
      {
        name: [string];
        data: [object];
      },
    ]
  \n-\`customInlineStyles\` - inline styles object for the \`SimpleContainer\` component;\n
  -\`isOptional\` - field indicating that the \`SimpleContainer\` can only be displayed under a certain condition, which is specified in this object;\n
  The \`isOptional\` field structure must be an *Object*:\n
    {
      test: \`condition_value\`
    }
`;

export default {
  title: "Containers/SimpleContainer",
  component: SimpleContainer,
  parameters: {
    docs: {
      description: {
        component: componentDescription,
      },
    },
    backgrounds: {
      default: 'light beige',
    },
  },
} as Meta;

const Template: Story<Props> = (args) => <SimpleContainer {...args} />;

export const Default = Template.bind({});
Default.args = {
  data: {
    children: [
      {
        "name": "SectionText",
        ...SectionTextStories.WithTextCustomization.args,
      }
    ]
  }
};

export const TopBannerCustomization = Template.bind({});
const customBannerDescription = `
  Each business can have several Products and each Product can have its own banner. Banner images for the Products are stored on *s3*.\n
  If the Product doesn't have its own image on *s3*, then the background banner will be the default image from field \`src\` of the \`data\` object.\n
  The **url** of the *Banner* on s3 is formed like this:\n
      const cardUrl = <REACT_APP_BUCKET><business>/v1/non-traced/<productName>.jpg;\n
  >where: *REACT_APP_BUCKET* - env variable that contains a link to the s3 bucket;\n
  >*business* - business name that is defined in the \`business\` field of \`data\` object;\n
  >*productName* - \`product\` field value. Value converted to lower case and spaces replaced with '_';\n
`;
TopBannerCustomization.parameters = {
  docs: {
    description: {
      story: customBannerDescription,
    },
  },
};
TopBannerCustomization.args = {
  data: {
    customize: true,
    business: "soufflet",
    product: "brasserie_de_chandres_harvesting",
    src: "/images/restitution/soufflet/bandeau-header.png",
    additionalClassNames: ["spa-top-banner"],
  }
};

export const ProgressBar = Template.bind({});
ProgressBar.args = {
  data: {
    children: [
      {
        "name": "ProgressBar",
        ...ProgressBarStories.WithArrow.args,
      },
      {
        "name": "ProgressBar",
        "data": {
          ...ProgressBarStories.WithArrow.args.data,
          "image": "/images/restitution/crc/ecrasement.svg",
          "label": "Écrasement",
          "text": "21 février 2022",
        },
      },
      {
        "name": "ProgressBar",
        ...ProgressBarStories.WithoutArrow.args,
      }
    ],
    "additionalClassNames": ["storybook-progress-bar-wrapper"],
  }
};

export const WithCustomInlineStyles = Template.bind({});
const customInlineDescription = `
  \`customInlineStyles\` is an Object where object key is *CSS property name* and object value is *CSS property value*.\n
  Since the inline CSS is written in a JavaScript object, properties with two names, like \`border-color\`, must be written with camel case syntax: \`borderColor\`.\n
  \`customInlineStyles\` recommended to use when you need to apply dynamic style values. For example: dynamic background color. It is not recommended to use for the static styles.\n
  For static styles values it is recommended to use \`additionalClassNames\`.
`;
WithCustomInlineStyles.parameters = {
  docs: {
    description: {
      story: customInlineDescription,
    },
  },
};
WithCustomInlineStyles.args = {
  data: {
    ...Default.args.data,
    additionalClassNames: ["spa-padding-1", "text-center"],
    customize: true,
    customInlineStyles: {
      border: "2px solid #2691c4",
    }
  }
};