import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";

const BusinessProductDetails = () => {
  const [rootSchema, setRootSchema] = useState();
  const { business, product, ean } = useParams();
  useEffect(() => {
    async function fetchAPI() {
      const response = await fetch(`/api/schemas/${business}/${ean}`);
      const { root } = await response.json();
      setRootSchema(root);
    }
    fetchAPI();
  }, []);
  return <div>{rootSchema?.toString()}</div>;
};

export default BusinessProductDetails;
