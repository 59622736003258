import React from "react";
import { Story, Meta } from "@storybook/react";

import ListItem, { Props } from "./ListItem";

const componentDescription = `
  UI component with a structure:\n
  - on the left side of the component is **Item Icon**.\n
  - on the right side of the component there is any **Item Content**: text of any kind, links, etc.\n
  \n\n
  #####Detailed description of \`data\` object fields:\n
  -\`icon\` - link to the icon image for the **Item Icon** part of the component. There are no default styles for the icon image;\n
  -\`iconClasses\` - styles customization that will be applied to the \`icon\`;\n
  -\`children\` - *required* - the *Item Content* that will be located on the right side of the component.\n
  -\`childrenClasses\` - styles customization that will be applied to the \`children\`;\n
  -\`additionalClassNames\` - styles customization that will be applied to all *ListItem* component;\n
  -\`isOptional\` - field indicating that the \`ListItem\` can only be displayed under a certain condition, which is specified in this object;\n
  The \`isOptional\` field structure must be an *Object*:\n
    {
      test: \`condition_value\`
    }
  \n
`;

export default {
  title: "Containers/ListItem",
  component: ListItem,
  parameters: {
    docs: {
      description: {
        component: componentDescription,
      },
    },
  },
} as Meta;

const Template: Story<Props> = (args) => <ListItem {...args} />;

export const Example1 = Template.bind({});
Example1.args = {
  data: {
    icon: "/images/restitution/crc/check_mark.svg",
    children: [
      {
        "name": "SectionText",
        "data": {
          "text": "Agriculteurs",
          "additionalClassNames": [
            "section-text",
            "text-bold"
          ]
        }
      },
      {
        "name": "SectionText",
        "data": {
          "text": "50 agriculteurs produisent le blé destiné à fabriquer cette farine",
          "additionalClassNames": [
            "section-text"
          ]
        }
      }
    ]
  }
};

export const Example2 = Template.bind({});
Example2.args = {
  data: {
    icon: "/images/restitution/crc/check_mark.svg",
    children: [
      {
        "name": "SectionText",
        "data": {
          "text": "Farine type 65 pour baguette de tradition française",
          "additionalClassNames": []
        }
      }
    ]
  }
};