import React from "react";
import { Story, Meta } from "@storybook/react";

import NavBar, { Props } from "./NavBar";

const componentDescription = `
  UI component used to display the text and navigate to the previous page.\n
  Usually used on pages to go 'back' and display the page title in the Restitution.\n
  ####Detailed description of \`data\` object fields:\n
  -\`action\` - *required* - defines the action to be taken. Possible values: 'back';\n
  -\`text\` - text that will be displayed next to the Back icon;\n
  -\`src\` - link to the image that will be displayed inside *Navbar*;\`
  -\`additionalClassNames\` - styles customization that will be applied to the full *Navbar* component;\n
  -\`image\` - link to the icon that will trigger the navigation action;\n
  -\`imageClasses\` - styles customization that will be applied to the \`image\`;\n
  -\`dynamicColor\` - custom Navbar color. Detailed information [here](#with-dynamic-color) ;\`
`;

export default {
  title: "Components/NavBar",
  component: NavBar,
  parameters: {
    docs: {
      description: {
        component: componentDescription,
      },
    },
  },
} as Meta;

const Template: Story<Props> = (args) => <NavBar {...args} />

export const WithIconAndText = Template.bind({});
WithIconAndText.args = {
  data: {
    action: "back",
    text: "Page Title",
    image: "/images/restitution/crc/left_arrow.svg",
  },
};

export const WithIconAndImage = Template.bind({});
WithIconAndImage.args = {
  data: {
    action: "back",
    image: "/images/restitution/noah/left_arrow.svg",
    src: "/images/restitution/noah/big-logo.png",
    additionalClassNames: ["storybook-bgcolor-2"]
  },
};

export const WithoutIcon = Template.bind({});
WithoutIcon.args = {
  data: {
    action: "back",
    text: "Page Title",
  },
};

export const OnlyIcon = Template.bind({});
OnlyIcon.args = {
  data: {
    action: "back",
    image: "/images/restitution/crc/left_arrow.svg",
  },
};

export const WithCustomization = Template.bind({});
WithCustomization.args = {
  data: {
    ...WithIconAndText.args.data,
    additionalClassNames: ["storybook-bgcolor-2"]
  },
};

export const WithDynamicColor = Template.bind({});
const dynamicColorDescription = `
  Navbar can have specific color that can be defined from the Data model.
  \`dynamicColor\` variable is used to set this specific color from the Data model to Navbar.\n
  Usage Criteria:
  - it is used **only** for cases with dynamic data from the Data model;
  - \`dynamicColor\` value must be a **String**;
`;
WithDynamicColor.parameters = {
  docs: {
    description: {
      story: dynamicColorDescription,
    },
  },
};
WithDynamicColor.args = {
  data: {
    ...WithIconAndText.args.data,
    dynamicColor: "#9651bd"
  },
};