import React from "react";
import { Story, Meta } from "@storybook/react";

import Link, { Props } from "./Link";

const componentDescription = `
  UI component to display a Hyperlink. The link opens in a separate tab.\n
  #####Detailed description of \`data\` object fields:\n
  -\`src\` - *required* - link path (directly which should be opened);\n
  -\`text\` - link text - if \`text\` is not declared, then the link text on the page will be the same as the \`src\` value (assuming \`onlyImage: false\`);\n
  -\`onlyImage\` - specifies whether to display only icon for the link;\n
  -\`icon\` - link icon - will display near the link text; \n
  -\`additionalClassNames\` - styles customizations for the link container;\n
  -\`iconClasses\` - styles customizations for the icon;\n
  -\`textClasses\` - styles customizations for the link text;\n
  \n
  If \`src\` is not declared, then this component return \`null\` - nothing.
`;

export default {
  title: "Components/Link",
  component: Link,
  parameters: {
    docs: {
      description: {
        component: componentDescription,
      },
    },
  },
} as Meta;

const Template: Story<Props> = (args) => <Link {...args} />;

export const Default = Template.bind({});
Default.args = {
  data: {
    src: "https://www.google.com/",
  }
};

export const WithText = Template.bind({});
WithText.args = {
  data: {
    src: "https://www.google.com/",
    text: "Link to the google",
  }
};

export const WithIcon = Template.bind({});
WithIcon.args = {
  data: {
    ...WithText.args.data,
    icon: "/images/restitution/crc/video_icon.svg",
  }
};

export const WithCustomizations = Template.bind({});
WithCustomizations.args = {
  data: {
    ...WithIcon.args.data,
    additionalClassNames: [],
    iconClasses: ["d-flex"],
    textClasses: ["storybook-link-text"],
  }
};

export const WithImageOnly = Template.bind({});
WithImageOnly.args = {
  data: {
    src: "https://quefairedemesdechets.ademe.fr/",
    onlyImage: true,
    icon: "/images/restitution/agec/info-tri-standard.svg",
    additionalClassNames: ["storybook-image-link-wrapper"],
  }
};




