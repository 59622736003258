import React from "react";
import { Story, Meta } from "@storybook/react";

import ProgressBar, { Props } from "./ProgressBar";

const componentDescription = `
  Component structure: 
  - **Content** that includes: *Image*, *Label* below it and then *Text* \n
  - **Arrow**\n\n
  All parts of the component are optional.\n
  See how it looks like when multiple *ProgressBar* components are used - [Here](?id=containers-simplecontainer--progress-bar#progress-bar);
 
  #####Detailed description of \`data\` object fields:\n
  -\`image\` - link for the *Image* part of the component;\n
  -\`label\` - text for the *Label* part of the component;\n
  -\`text\` - text for the *Text* part of the component;\n
  -\`arrow\` - content for the *Arrow* part of the component;\n
  -\`additionalClassNames\` - styles customization that will be applied to all *ProgressBar* component;\n
  -\`imageClasses\` - styles customization that will be applied to the \`image\`;\n
  -\`labelClasses\` - styles customization that will be applied to the \`label\`;\n
  -\`textClasses\` - styles customization that will be applied to the \`text\`;\n
  -\`arrowClasses\` - styles customization that will be applied to the \`arrow\`;\n
  The \`arrow\` field structure must be :\n
    [
      {
        name: <string>;
        data: <object>;
      },
    ]
`;

export default {
  title: "Containers/ProgressBar",
  component: ProgressBar,
  parameters: {
    docs: {
      description: {
        component: componentDescription,
      },
    },
  },
} as Meta;

const Template: Story<Props> = (args) => <ProgressBar {...args} />;

export const Default = Template.bind({});
Default.args = {
  data: {
    "image": "/images/restitution/crc/camion.svg",
    "label": "Réception",
    "additionalClassNames": [],
    "text": "5 janvier 2022",
    "textClasses": [],
  }
};

export const WithoutArrow = Template.bind({});
WithoutArrow.args = {
  data: {
    "image": "/images/restitution/crc/farine.svg",
    "label": "Ensachage",
    "labelClasses": ["section-text", "text-center"],
    "additionalClassNames": [""],
    "text": "5 janvier 2022",
    "textClasses": [
      "section-text",
      "storybook-color-1",
      "text-bold"
    ],
  }
};

export const WithArrow = Template.bind({});
WithArrow.args = {
  data: {
    "image": "/images/restitution/crc/camion.svg",
    "additionalClassNames": ["flex-grow-1"],
    "label": "Réception",
    "labelClasses": ["section-text", "text-center"],
    "text": "5 janvier 2022",
    "textClasses": [
      "section-text",
      "storybook-color-1",
      "text-bold"
    ],
    "arrow": [
      {
        "name": "Image",
        "data": {
          "src": "/images/restitution/crc/chevron_right_yellow.svg",
          "additionalClassNames": []
        }
      }
    ]
  }
};

export const WithoutText = Template.bind({});
WithoutText.args = {
  data: {
    "image": "/images/restitution/crc/farine.svg",
    "label": "Ensachage",
    "labelClasses": ["section-text", "text-center"],
    "additionalClassNames": [""],
  }
};
