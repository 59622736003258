import React from "react";
import { Story, Meta } from "@storybook/react";

import LocationCard, { Props } from "./LocationCard";

const componentDescription = `
  Detailed description of \`data\` object fields:\n
  -\`additionalClassNames\` - styles customization that will be applied to all *LocationCard* component;\n
  -\`image\` - image that will be in the white circle on the right. Circle size: 50px x 50px;\n
  -\`imageClasses\` - styles customization that will be applied to the \`image\`;\n
  -\`text\` - text that will be located on the left side of the image.;\n
  -\`textClasses\` - styles customization that will be applied to the \`text\`;\n
`;

export default {
  title: "Components/LocationCard",
  component: LocationCard,
  parameters: {
    docs: {
      description: {
        component: componentDescription,
      },
    },
  },
} as Meta;

const Template: Story<Props> = (args) => <LocationCard {...args} />;

export const Basic = Template.bind({});
Basic.args = {
  data: {
    image: "/images/restitution/crc/location.svg",
    text: "Lorem ipsum dolor sit amet, consectetur adipiscing elit.",
    textClasses: ["spa-location-card-text"],
  }
};