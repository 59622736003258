import React from "react";
import { Story, Meta } from "@storybook/react";

import Accordion, { Props } from "./Accordion";

const componentDescription = `
  The UI component allows the user to show and hide sections of related content on a page.\n
  More detailed **description** of \`data\` object fields:\n
  -\`title\` - content to be displayed in the title of the Accordion;\n
  -\`content\` - content that is initially hidden from the user. Displayed only after clicking on the \`title\` of the Accordion;\n
  -\`additionalClassNames\` - styles customization that will be applied to all Accordion component;\n
  -\`titleClasses\` - styles customization that will be applied to the \`title\` of the Accordion;\n
  -\`contentClasses\` - styles customization that will be applied to the \`content\` of the Accordion;\n
`;


export default {
  title: "Containers/Accordion",
  component: Accordion,
  parameters: {
    docs: {
      description: {
        component: componentDescription,
      },
    },
  },
} as Meta;

const Template: Story<Props> = (args) => <Accordion {...args} />;

export const Default = Template.bind({});
Default.args = {
  data: {
    title: [{
      name: "SectionText",
      data: {
        text: "Accordion title - click here"
      }
    }],
    content: [
      {
        name: "SectionText",
        data: {
          text: "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.",
        }
      }
    ]
  }
};

export const WithCustomization = Template.bind({});
WithCustomization.args = {
  data: {
    additionalClassNames: ["storybook-accordion"],
    title: [
      {
        name: "SectionText",
        data: {
          text: "Accordion title"
        }
      },
      {
        "name": "Image",
        "data": {
          "src": "/images/restitution/meo/right_arrow.svg",
          "additionalClassNames": ["storybook-accordion-icon"]
        }
      },
    ],
    titleClasses: ["storybook-accordion-title"],
    content: [
      {
        name: "SectionText",
        data: {
          text: "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.",
        },
      },
    ],
    contentClasses: ["storybook-accordion-content"],
  },
};